import React from 'react';

import { graphql } from 'gatsby';
import { motion } from 'framer-motion';
import HTMLReactParser from 'html-react-parser';

import ContactForm from 'components/contact-form';

import { white } from 'theme/colors';
import { pageAnims } from 'theme/animations';

const TemplatePolicy = (props) => {
  //PROPS
  const {
    lang,
    data: {
      page: {
        privacidade: { blocoDeTexto },
      },
    },
  } = props;
  //PROPS

  return (
    <>
      <motion.div
        style={{
          background: white.default,
          minHeight: '100vh',
        }}
        variants={pageAnims}
        initial="initial"
        animate="animate"
        exit="exit"
      >
        <section className="container container--small">
          {blocoDeTexto.map((text, index) => {
            return (
              <div key={index}>
                {text.titulo && <h2>{text.titulo}</h2>}
                {HTMLReactParser(text.texto)}
              </div>
            );
          })}
        </section>
      </motion.div>
      <ContactForm lang={lang} />
    </>
  );
};

export const query = graphql`
  query Policy($id: String) {
    page: wpPage(id: { eq: $id }) {
      title
      id
      privacidade {
        blocoDeTexto {
          texto
          titulo
        }
      }
    }
  }
`;

export default TemplatePolicy;
